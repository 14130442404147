import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './Styles/Style.css'
import {data,vishnuData} from './mock';
import ProfileView from './Components/ProfileView';
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Routes>
      <Route path='/' element={<ProfileView data={data} />} />
      <Route path='vishnu' element={<ProfileView data={vishnuData} />} />
      <Route path=":userID" element={<ProfileView data={vishnuData} /> }/>
      <Route
      path="*"
      element={
        <main style={{ padding: "1rem" }}>
          <p>There's nothing here!</p>
        </main>
      }
    />
    </Routes>
  );
}


export default App;
