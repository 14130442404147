import React from 'react'
import CompactSocialItem from './CompactSocialItem'

const CompactSocial = ({ socialData }) => {
    return (
        <>
            <br />
            <div className='row'>
                {socialData.map(social => <CompactSocialItem social={social} key={social.type+social.handle}></CompactSocialItem>)}
            </div>
        </>
    )
}

export default CompactSocial