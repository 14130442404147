import profile from './Assets/profile.jpeg'
import tappo from './Assets/tappo.vcf'
import vishnu from './Assets/vishnu.vcf'

const socialDataTappo = [
    {
        "type" : "insta",
        "icon" : "insta",
        "title" : "Instagram",
        "handle" : "mytappo"
    },
    {
        "type" : "twitter",
        "icon" : "twitter",
        "title" : "Twitter",
        "handle" : "mytappo"
    },
    {
        "type" : "linkedinc",
        "icon" : "linkedin",
        "title" : "LinkedIn",
        "handle" : "mytappo"
    },
    // {
    //     "type" : "facebook",
    //     "icon" : "facebook",
    //     "title" : "Facebook",
    //     "handle" : "mytappo"
    // }
]

const socialDataVishnu = [
    {
        "type" : "insta",
        "icon" : "insta",
        "title" : "Instagram",
        "handle" : "vishnupoothery"
    },
    {
        "type" : "twitter",
        "icon" : "twitter",
        "title" : "Twitter",
        "handle" : "vishnu_poothery"
    },
    {
        "type" : "linkedin",
        "icon" : "linkedin",
        "title" : "LinkedIn",
        "handle" : "vishnupoothery"
    },
    // {
    //     "type" : "facebook",
    //     "icon" : "facebook",
    //     "title" : "Facebook",
    //     "handle" : "mytappo"
    // }
]

export const data = {
    "username" : "mytappo",
    "name": "Tappo",
    "profile" : profile,
    "pronoun" : "He/Him",
    "address" : "India",
    "tagLine" : "A single tap is all it takes",
    "socials" : socialDataTappo,
    "contact" : tappo
}

export const vishnuData = {
    "username" : "vishnu",
    "name": "Vishnu Poothery",
    "profile" : profile,
    "pronoun" : "He/Him",
    "address" : "India",
    "tagLine" : "Software Developer",
    "socials" : socialDataVishnu,
    "contact" : vishnu
}