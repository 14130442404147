import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Styles/Style.css'
import { ImUserPlus } from 'react-icons/im'
import SpacedSocial from '../Components/SpacedSocial';
import CompactSocial from '../Components/CompactSocial';
import hero from '../Assets/hero.jpg';
import { useEffect } from 'react'
import { useParams } from "react-router-dom";

const ProfileView = ({ data }) => {
    let params = useParams();
    useEffect(() => {
        document.title = data.name;
    }, []);
    return (
        <>
            <div className='card-container'>
                <div className='hero-box'>
                    <img src={hero} className="hero-image" />
                </div>
                <div className='profile-container'>
                    <div className='text-center'>
                        <img src={data.profile} alt="Profile" className='profile-pic' />
                        <h2>{data.name} {params.userID}</h2>
                        {/* <h5>{data.address}</h5> */}
                        <h5>{data.tagLine}</h5>
                    </div>
                    <hr />
                    <div className="text-center">
                        <a href={data.contact}><button className='contact-button'> <ImUserPlus /> Save contact </button></a>
                    </div>
                    <CompactSocial socialData={data.socials} />
                    {/* <SpacedSocial socialData={data.socials} /> */}
                    {/* <div>
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/E7wJTI-1dvQ"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="video"
              autpl
            />{" "}
          </div> */}
                    <br />
                    <br />
                    <div className='fixed-bottom text-center footer'>Powered by <span className='powered-by-text'>tappo</span></div>
                </div>
            </div>
        </>
    )
}

export default ProfileView