import React from 'react'
import { ExternalLink } from 'react-external-link'
import { getUrl, getIcon } from '../Utils/socialUtils'

const CompactSocialItem = ({ social }) => {
    return (
        <div className='social-box col-4'>
            <div className="social-item text-center">
                <ExternalLink href={getUrl(social.type, social.handle)}>
                    <img src={getIcon(social.icon)} alt={social.title} className='social-icon' />
                </ExternalLink>
                <p className=''>{social.title}</p>
            </div>
        </div>
    )
}

export default CompactSocialItem