import insta from '../Assets/insta.png'
import twitter from '../Assets/twitter.png'
import linkedin from '../Assets/linkedin.png'
import facebook from '../Assets/facebook.png'

export const getUrl = (type,username) => {
    switch (type) {
        case 'insta' :
            return `http://www.instagram.com/${username}`
        case 'twitter' :
            return `https://twitter.com/${username}`
        case 'linkedin' :
            return `https://www.linkedin.com/in/${username}`
            case 'linkedinc' :
            return `https://www.linkedin.com/company/${username}`
        case 'facebook' :
            return `https://www.facebook.com/${username}`
        case 'link':
            return username
    }
}

export const getIcon = (icon) =>{
    switch (icon){
        case 'insta' :
            return insta
        case 'twitter' :
            return twitter
        case 'linkedin' :
            return linkedin
        case 'facebook' :
            return facebook
    }
}